import loadable from "@loadable/component"
import { graphql, PageProps } from "gatsby"
import React from "react"
import DefaultLayout from "../components/layouts/DefaultLayout"
// import { PageContext } from "../context/PageContext"
// import { UserContext } from "../context/UserContext"
import { isBrowser } from "../utils"

const Page = loadable(() => import("../components/pages/Page"))
const ProductPage = loadable(() => import("../components/pages/ProductPage"))
const ProductSearchPage = loadable(() => import("../components/pages/ProductSearchPage"))
const ProductLandingPage = loadable(() => import("../components/pages/ProductLandingPage"))

const DatoRoute = (props: PageProps<Queries.DatoRouteQuery, Queries.DatoRouteQueryVariables>) => {
  // console.debug("Rendering DatoRoute:", props)

  const { data } = props

  if (!data) {
    console.error("No data for gatsby-template datoRoute")
    return null
  }

  if (!data.currentRoute?.page) {
    console.error("No page in route at gatsby-template datoRoute", data.currentRoute)
    return null
  }

  let content: JSX.Element | null = null
  const { __typename, seoMetaTags, ...rest } = data.currentRoute.page

  switch (data.currentRoute.page.__typename) {
    case "DatoCmsPage":
      content = <Page {...rest} />
      break
    case "DatoCmsProductSearchPage": {
      /*
      Jos queryssä parametri id, näytetään dynaamisesti ladattu tuotedata.
      */
      const tempId = isBrowser && new URLSearchParams(window.location.search).get("id")
      if (tempId) {
        // näytetään dynaaminen tuotesivu
        content = <ProductPage sku={tempId} />
      } else {
        // Tuotehakusivu
        content = <ProductSearchPage {...rest} />
      }
      break
    }
    case "DatoCmsProductLandingPage":
      content = <ProductLandingPage {...rest} />
      break
    default:
      console.error("Unknown Dato Route Page type", data.currentRoute.page)
  }

  return (
    <DefaultLayout mainMenu={data.website?.mainMenu} footer={data.website?.footer} seoMetaTags={seoMetaTags}>
      {content}
    </DefaultLayout>
  )
}

export const query = graphql`
  # $originalId parametri viittaa lokalisoimattomaan route recordiin
  query DatoRoute($originalId: String!, $locale: String!, $fallbackLocales: [String!]) {
    website: datoCmsWebsite(locale: $locale, fallbackLocales: $fallbackLocales) {
      mainMenu {
        ...DatoMainNavTree
      }
      footer {
        ...DatoFooter
      }
    }
    currentRoute: datoCmsRoute(originalId: { eq: $originalId }, locale: $locale, fallbackLocales: $fallbackLocales) {
      slug
      page {
        __typename
        ... on DatoCmsPage {
          seoMetaTags {
            tags
          }
          ...DatoPage
        }
        ... on DatoCmsProductLandingPage {
          seoMetaTags {
            tags
          }
          ...DatoProductLandingPage
        }
        ... on DatoCmsProductSearchPage {
          seoMetaTags {
            tags
          }
          ...DatoProductSearchPage
        }
      }
    }
  }
`

export default DatoRoute
